<template>
  <div>
    <vca-map
      ref="map"
      :disabled="true"
      :hideButtons="mappedLocation.length <= 1"
      v-model="mappedLocation"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EventMap",
  data() {
    return {
      mappedLocation: [
        {
          ...this.value.location,
          ...{
            title: this.value.location.name,
            subtitle:
              (this.value.location.street
                ? this.value.location.street + " "
                : "") +
              (this.value.location.number
                ? this.value.location.number + ", "
                : "") +
              (this.value.location.zip ? this.value.location.zip + " " : "") +
              (this.value.location.city
                ? this.value.location.city + ", "
                : "") +
              (this.value.location.country
                ? this.value.location.country + " "
                : ""),
          },
        },
      ],
    };
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    api_lang: {
      handler(value) {
        if (this.$refs.map) {
          this.$refs.map.setLanguage(value);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      api_lang: "api_lang",
    }),
  },
};
</script>
