<template>
  <div class="wrapper" ref="popover">
    <button
      class="main-color vca-border"
      @click.prevent="showPopover = !showPopover"
    >
      &vellip;
    </button>

    <div v-if="showPopover" class="popover vca-border">
      <ul>
        <li v-for="option in options" :key="option[keyProperty]">
          <slot v-bind="option">{{ option.text }}</slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    keyProperty: {
      type: String,
      default: "key",
    },
  },
  methods: {
    onClickOutside(event) {
      const { popover } = this.$refs;
      if (!popover || popover.contains(event.target) || !this.showPopover)
        return;
      this.showPopover = false;
    },
  },
  mounted() {
    document.addEventListener("click", this.onClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onClickOutside);
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
}

.wrapper > button {
  padding: 12px;
  font-weight: 600;
  background-color: #fff;
  cursor: pointer;
}

.popover {
  position: absolute;
  top: 36px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0;
  z-index: 10;
}

.popover ul {
  list-style: none;
  padding: 0;
}

.popover button {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  border: none;
  cursor: pointer;
  background-color: #fff;
}

.popover button img {
  height: 16px;
  width: 16px;
  padding-right: 8px;
}
</style>
