<template>
  <vca-card>
    <h2>{{ $t("profile.events.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <AccountEventAspTable />
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import AccountEventAspTable from "@/components/account/events/AccountEventAspTable";
export default {
  name: "AccountEvents",
  components: { AccountEventAspTable },
  data() {
    return {
      eventList: [],
    };
  },
  created() {
    this.$store.commit("user/aspevents/list", []);
    this.$store.dispatch({ type: "events/listAsp" }).then((response) => {
      this.eventList =
        !response || response.length == 0
          ? []
          : response.filter((el) => {
              el.is_event_asp = true;
              return true;
            });
      if (!this.hasSystemPermission()) {
        this.$store.commit("user/aspevents/list", this.eventList);
        this.$store.commit("user/aspevents/pg/pageSize", this.eventList.length);
      } else {
        let data = {
          internal_asp_id: this.user.id,
        };
        this.$store
          .dispatch({ type: "events/queryList", data: data })
          .then((response) => {
            const internalList =
              !response || response.length == 0 ? [] : response;

            let all = [...this.eventList];
            Array.prototype.push.apply(all, internalList);

            let result = [];
            all.forEach((el) => {
              el.is_event_asp = this.eventList.some(
                (entry) => entry.id == el.id
              );
              el.is_internal_asp = internalList.some(
                (entry) => entry.id == el.id
              );
              if (!result.some((entry) => entry.id == el.id)) {
                result.push(el);
              }
            });
            this.$store.commit("user/aspevents/list", result);
            this.$store.commit("user/aspevents/pg/pageSize", result.length);
          });
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "user/current",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
